import MainLayout from "../ui/Layout/MainLayout";
import { Settings } from "../ui/Layout/settings/Settings";

const ConfigView = ({ children }) => {
  return (
    <MainLayout>
      <Settings>{children}</Settings>
    </MainLayout>
  );
};

export default ConfigView;
