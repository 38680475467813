import axios from "axios";

export const newInvitation = async (key, data) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_PRODUCCION}/instance/newInvitation`,
    headers: {
      Authorization: key,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const res = axios(config);

  return await res;
};

export const manageInvitation = async (data) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/manageInvitation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getInvitationsByInstance = async (key) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/instance/getInvitationsByInstance`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
