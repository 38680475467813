import { useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import LoadingIcon from '../../Loader/LoadingIcon';
import { SelectClientes } from '../../selectClientes/SelectClientes';
import { GlobalContext } from '../../../context/GlobalContext';
import { getAssociatedClients } from '../../../../helpers/fetchData/fetchClients';

export const AssociateClients = ({
  editMode,
  form,
  clients,
  setClients,
  item
}) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [initialClients, setInitialClients] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey && item && form && editMode) {
      setLoadingClientes(true);
      getAssociatedClients(instance, item._id)
        .then((res) => {
          if (res?.status === 200) {
            setInitialClients(res.data || []);

            const ids = res.data?.map((c) => c._id);
            setClients(ids);
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error(
            'No se pudo obtener los clientes asociados al contacto'
          );
        })
        .finally(() => setLoadingClientes(false));
    }
  }, [instance, item, form, editMode]);

  return (
    <Form
      className='form-nodos'
      form={form}
      name='selectClients'
      layout='vertical'
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Clientes</p>

      {loadingClientes ? (
        <span
          style={{
            height: 300,
            width: '100%',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <LoadingIcon size='large' />
        </span>
      ) : (
        <Form.Item
        // name='clients'
        >
          <SelectClientes
            value={clients}
            setValue={setClients}
            mode='multiple'
            existingClients={initialClients}
          />
        </Form.Item>
      )}
    </Form>
  );
};
