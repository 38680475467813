// Validar un solo formulario:
export const validateOneForm = (form, formName) => {
  return form
    .validateFields()
    .then((values) => {
      return { formName, isValid: true, values, errorFields: [] };
    })
    .catch((err) => {
      return {
        formName,
        isValid: false,
        values: {},
        errorFields: err.errorFields
      };
    });
};
