import { associateContactToClients } from '../../../../helpers/fetchData/fetchContacts';

export const fetchAssociateClients = async (data, instance, messageApi) => {
  associateContactToClients(instance?.userData?.apiKey, data)
    .then()
    .catch((err) => {
      messageApi.error('Hubo un error al asociar el contacto con los clientes');
      console.error(err);
    });
};
