import { useContext, useEffect } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { Form, Select } from "antd";
import { Tag } from "../../tag/Tag";

const MAX_TAG_COUNT = 5;

export const TagContact = ({ editMode, form, item }) => {
  const { etiquetas } = useContext(GlobalContext);

  // Setear etiquetas actuales al editar un contacto:
  useEffect(() => {
    if (editMode) {
      const contactTags = etiquetas
        ?.filter((etiqueta) => item?.tags?.includes(etiqueta._id))
        .map((etiqueta) => etiqueta._id);

      form.setFieldsValue({ tags: contactTags });
    }
  }, [etiquetas, item, form, editMode]);

  // Etiquetas a mostrar en el selector:
  const options = etiquetas.map((etiqueta) => ({
    label: (
      <Tag hex={etiqueta.color} nombre={etiqueta.name} key={etiqueta._id} />
    ),
    value: etiqueta._id,
  }));

  // Filtrar etiquetas en el selector:
  const filterOption = (value, option) => {
    return option?.label?.props?.nombre
      ?.toLowerCase()
      ?.includes(value.toLowerCase());
  };

  return (
    <Form
      className="form-nodos"
      form={form}
      name="contactTags"
      layout="vertical"
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Etiquetas</p>

      <Form.Item name="tags">
        <Select
          mode="multiple"
          allowClear
          maxTagCount={MAX_TAG_COUNT}
          placeholder="Seleccione etiquetas"
          options={options}
          filterOption={filterOption}
          showSearch
        />
      </Form.Item>
    </Form>
  );
};
