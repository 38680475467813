import { Modal } from 'antd';
import { useState, useContext } from 'react';
import { FiTag, FiUploadCloud, FiUsers } from 'react-icons/fi';
import { GlobalContext } from '../../../../../context/GlobalContext';
import { useAccess } from '../../../../../../hooks/useAccess';
import { accessDataFormat } from '../../../../../../helpers/access';
import { PiAddressBookBold } from 'react-icons/pi';
import { ContactEditor } from '../../../../drawers/ContactEditor';

export const NewHandlerModal = ({ open, setOpen }) => {
  const isAllowed = useAccess();

  const [isOpenContactEditorDrawer, setIsOpenContactEditorDrawer] =
    useState(false);

  function handleCancel() {
    setOpen(false);
  }

  return (
    <>
      <Modal
        title='Nuevo elemento'
        open={open}
        width={400}
        footer={null}
        destroyOnClose
        onCancel={handleCancel}
      >
        <div className='select-trigger-card-container'>
          {isAllowed(accessDataFormat('contacts', 'manage')) && (
            <NewElementCard
              title='Contacto'
              value='contact'
              icon={<PiAddressBookBold size={42} />}
              setIsOpenContactEditorDrawer={setIsOpenContactEditorDrawer}
            />
          )}

          {isAllowed(accessDataFormat('tags', 'manage')) && (
            <NewElementCard
              title='Etiqueta'
              value='tag'
              icon={<FiTag size={42} />}
            />
          )}

          <NewElementCard
            title='Media'
            value='media'
            icon={<FiUploadCloud size={42} />}
          />
        </div>
      </Modal>

      {isOpenContactEditorDrawer && (
        <ContactEditor
          isOpenDrawer={isOpenContactEditorDrawer}
          setIsOpenDrawer={setIsOpenContactEditorDrawer}
          isQuickAction
        />
      )}
    </>
  );
};

const NewElementCard = ({
  title,
  value,
  icon,
  setIsOpenContactEditorDrawer
}) => {
  const { setSideDrawerConfig } = useContext(GlobalContext);

  function handleClick() {
    if (value === 'contact') {
      setIsOpenContactEditorDrawer(true);
    } else {
      setSideDrawerConfig({
        visible: true,
        content: 'plus',
        tab: value
      });
    }
  }

  return (
    <div className='SelectTriggerCard' onClick={handleClick}>
      <span className='horizontal'>{icon}</span>
      <span>{title}</span>
    </div>
  );
};
