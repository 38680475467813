import { Drawer, Menu } from "antd";
import { useState } from "react";
import { settingsMenuItems } from "./utils/smallUtilities";
import { useNavigate } from "react-router-dom";
import { NewProduct } from "./content/drawerContent/NewProduct";
import { NewList } from "./content/drawerContent/lists/NewList";
import { EditList } from "./content/drawerContent/lists/EditList";
import { UpdateProduct } from "./content/drawerContent/UpdateProduct";
import { SettingsContext } from "../../../context/SettingsContext";
import { useAccess } from "../../../../hooks/useAccess";
import { EditStatus } from "./content/drawerContent/statuses/EditStatus";
import { NewStatus } from "./content/drawerContent/statuses/NewStatus";
import { ManageListProducts } from "./content/drawerContent/lists/ManageListProducts";
import { useIsDevsInstance } from "../../../../hooks/useIsDevsInstance";

export const Settings = ({ children }) => {
  const [configDrawerContent, setConfigDrawerContent] = useState({
    visible: false,
    content: "",
    item: null,
  });
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [selectedKeys, setSelectedKeys] = useState(["sub1"]);

  //! hay que descartarlos, revisar donde se usa.
  const [catalogDetail, setCatalogDetail] = useState(null);
  const [selectedCatalog, setSelectedCatalog] = useState();
  const [stage, setStage] = useState();
  //!

  const [recursos, setRecursos] = useState([]);

  const isAllowed = useAccess();

  const { isDevsInstance } = useIsDevsInstance();

  const navigate = useNavigate();

  const handleDrawerContent = () => {
    const { content } = configDrawerContent;

    switch (content) {
      case "newProduct":
        return <NewProduct />;
      case "updateProduct":
        return <UpdateProduct />;
      case "newList":
        return <NewList />;
      case "newStatus":
        return <NewStatus />;
      case "editList":
        return <EditList />;
      case "editStatus":
        return <EditStatus />;
      case "manageListProducts":
        return <ManageListProducts />;
      default:
        break;
    }
  };

  const handleDrawerWidth = () => {
    const { content } = configDrawerContent;

    if (["newConjunto", "updateConjunto"].includes(content)) {
      return 600;
    } else if (content === "manageListProducts") {
      return 850;
    } else {
      return 450;
    }
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleSelectedItem = (key) => {
    navigate(`/settings/${key}`);
    setSelectedKeys(key);
  };

  return (
    <SettingsContext.Provider
      value={{
        selectedCatalog,
        setSelectedCatalog,
        stage,
        setStage,
        catalogDetail,
        setCatalogDetail,
        recursos,
        setRecursos,
        configDrawerContent,
        setConfigDrawerContent,
      }}
    >
      <div className="config-module-wrapper">
        <div className="config-main-menu">
          <p className="config-module-title">Configuración</p>
          <Menu
            mode="inline"
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            forceSubMenuRender={true}
            defaultOpenKeys={["sub1"]}
            onOpenChange={onOpenChange}
            style={{
              width: 200,
              fontSize: "13px",
            }}
            onClick={(v) => handleSelectedItem(v.key)}
            items={settingsMenuItems(isAllowed, isDevsInstance)}
          />
        </div>
        <div className="config-content-wrapper">{children}</div>
        <Drawer
          placement="right"
          open={configDrawerContent?.visible}
          destroyOnClose
          onClose={() => {
            setConfigDrawerContent({ visible: false, content: "", item: null });
          }}
          closeIcon={null}
          styles={{ header: { display: "none" }, body: { padding: "24px" } }}
          width={handleDrawerWidth()}
        >
          {handleDrawerContent()}
        </Drawer>
      </div>
    </SettingsContext.Provider>
  );
};
