import { editContact } from '../../../../helpers/fetchData/fetchContacts';
import { fetchAssociateClients } from './fetchAssociateClients';

export const handleSaveEdit = async (
  formattedValues,
  item,
  setContactos,
  clients,
  handleClose,
  setLoading,
  instance,
  messageApi
) => {
  formattedValues = { ...formattedValues, _id: item._id };

  // 1. Edita el contacto:
  editContact(instance, formattedValues)
    .then((res) => {
      if (res?.status === 200) {
        messageApi.success('Contacto editado correctamente');

        // 2. Actualiza la tabla:
        setContactos((prevState) => {
          let array = [...prevState];

          const index = array.findIndex(
            (contacto) => contacto._id === item._id
          );

          if (index >= 0) {
            array[index] = {
              ...array[index],
              ...formattedValues,
              clients
            };
          }

          return array;
        });

        const associateClientsData = {
          contactId: item._id,
          clientsIds: clients
        };

        // 3. Asocia al contacto con los clientes indicados en el formulario:
        fetchAssociateClients(associateClientsData, instance, messageApi);
        handleClose();
      } else {
        messageApi.error('Ocurrió un error al editar el contacto');
      }
    })
    .catch((err) => {
      console.error(err);
      if (err?.response?.data?.message) {
        messageApi.error(err.response.data.message);
      } else {
        messageApi.error('Ocurrió un error al crear el contacto');
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
