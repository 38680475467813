import axios from 'axios';

export const getContacts = async (instance) => {
  try {
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/getContacts`,
      headers: {
        Authorization: `${instance.userData?.apiKey}`,
        'Content-Type': 'application/json'
      }
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newContact = async (instance, data) => {
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/addContact`,
    data: data,
    headers: {
      Authorization: `${instance?.userData?.apiKey}`,
      'Content-Type': 'application/json'
    }
  };

  const res = axios(config);

  return await res;
};

export const editContact = async (instance, data) => {
  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/editContact`,
    data: data,
    headers: {
      Authorization: `${instance?.userData?.apiKey}`,
      'Content-Type': 'application/json'
    }
  };

  const res = axios(config);

  return await res;
};

export const executeExternalContactsLoad = async (key) => {
  try {
    if (key) {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/executeExternalContactsLoad`,
        headers: {
          Authorization: key
        }
      };

      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const executeExternalContactsLoadExcel = async (key, data) => {
  try {
    if (key) {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/executeExternalContactsLoadExcel`,
        headers: {
          Authorization: key,
          'Content-Type': 'application/json'
        },
        data: data
      };

      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const associateContactToClients = async (key, data) => {
  try {
    if (key) {
      const config = {
        method: 'put',
        url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/associateContactToClients`,
        headers: {
          Authorization: key
        },
        data: data
      };

      const res = await axios(config);

      return res;
    }
  } catch (err) {
    console.error(err);
  }
};

export const multipleContactsByIds = async (key, data) => {
  try {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/multipleContactsByIds`,
      headers: {
        Authorization: key
      },
      data: { ids: data }
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getContact = async (instance, params) => {
  try {
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/getContact`,
      headers: {
        Authorization: `${instance.userData?.apiKey}`
      },
      params
    };
    const res = await axios(config);

    return res;
  } catch (err) {
    console.log(err);
  }
};

// CONTACTOS ARCHIVADOS:
export const archiveContact = async (instance, item) => {
  try {
    const config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/archiveContact/${item.phones?.[0]?.phone}`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const unarchiveContact = async (instance, item) => {
  try {
    const config = {
      method: 'put',
      url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/unarchiveContact/${item.phones?.[0]?.phone}`,
      headers: {
        Authorization: instance?.userData?.apiKey
      },
      data: {
        number: item.phones?.[0]?.phone
      }
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getArchivedContacts = async (instance) => {
  try {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_API_PRODUCCION}/contacts/getArchivedContacts`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};
