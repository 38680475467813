// Divide un nombre completo en primer nombre, segundo nombre y apellido/s

export const parseFullName = (fullName) => {
  if (!fullName || typeof fullName !== 'string') {
    return { first_name: '', middle_name: '', last_name: '' };
  }

  const parts = fullName.trim().split(' ');
  let first_name = '';
  let middle_name = '';
  let last_name = '';

  switch (parts.length) {
    case 1:
      first_name = parts[0];
      break;
    case 2:
      first_name = parts[0];
      last_name = parts[1];
      break;
    case 3:
      first_name = parts[0];
      middle_name = parts[1];
      last_name = parts[2];
      break;
    default:
      first_name = parts[0];
      middle_name = parts[1];
      last_name = parts.slice(2).join(' '); // El apellido incluye la tercera palabra en adelante
  }

  return { first_name, middle_name, last_name };
};
