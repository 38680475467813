import { useContext, useEffect } from 'react';
import { Form, Input } from 'antd';
import LoadingIcon from '../../Loader/LoadingIcon';
import { GlobalContext } from '../../../context/GlobalContext';

export const ContactAdditionalFields = ({ editMode, form, loading, item }) => {
  const { additionalFields } = useContext(GlobalContext);

  // Setear campos adicionales actuales al editar un contacto:
  useEffect(() => {
    if (item && item?.additionalFields?.length > 0 && editMode) {
      const initialValues = {};

      item.additionalFields?.forEach((field) => {
        initialValues[field._id] = field.value;
      });

      form.setFieldsValue(initialValues);
    }
  }, [form, editMode, item]);

  return (
    <Form
      className='form-nodos'
      form={form}
      name='editAdditionalFields'
      layout='vertical'
    >
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Campos adicionales</p>

      {loading ? (
        <span
          style={{
            height: 300,
            width: '100%',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <LoadingIcon size='large' />
        </span>
      ) : (
        <>
          {additionalFields?.length > 0 ? (
            additionalFields.map((field, index) => (
              <AdditionalFieldInput key={index} additionalField={field} />
            ))
          ) : (
            <p>No hay campos adicionales</p>
          )}
        </>
      )}
    </Form>
  );
};

const AdditionalFieldInput = ({ additionalField }) => {
  if (!additionalField) {
    return null;
  }

  return (
    <Form.Item name={additionalField._id} label={additionalField.name}>
      {additionalField.type === 'text' ? (
        <Input.TextArea />
      ) : additionalField.type === 'number' ? (
        <Input type='number' />
      ) : (
        <Input />
      )}
    </Form.Item>
  );
};
