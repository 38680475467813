import { newContact } from '../../../../helpers/fetchData/fetchContacts';
import { deleteLead } from '../../../../helpers/fetchData/fetchContactsLeads';
import { fetchAssociateClients } from './fetchAssociateClients';

export const handleSaveAdd = async (
  formattedValues,
  setContactos,
  clients,
  item,
  setLeads,
  setLoading,
  handleClose,
  leadMode,
  instance,
  messageApi
) => {
  // 1. Añade el contacto:
  newContact(instance, formattedValues)
    .then((res) => {
      if (res?.status === 201) {
        messageApi.success('Contacto creado correctamente');

        // 2. Añade el contacto a la tabla:
        setContactos((prevState) => {
          let newArray = [...prevState];

          const nuevoContacto = {
            _id: res.data?._id,
            ...formattedValues,
            clients
          };

          newArray.push(nuevoContacto);

          return newArray;
        });

        const associateClientsData = {
          contactId: res.data?._id,
          clientsIds: clients
        };

        // 3. Asocia al contacto con los clientes indicados en el formulario:
        fetchAssociateClients(associateClientsData, instance, messageApi);

        // Si hay data de lead, actualizar / eliminar el lead:
        if (leadMode && item?._id) {
          deleteLead(instance?.userData?.apiKey, item._id)
            .then((res) => {
              if (res?.status === 200) {
                setLeads((prevState) => {
                  let array = [...prevState];

                  array = array.filter((element) => element._id !== item._id);

                  return array;
                });
              }
            })
            .catch((error) => {
              console.log('ERROR', error);
            })
            .finally(() => handleClose());
        } else {
          handleClose();
        }
      } else {
        messageApi.error('Ocurrió un error al crear el contacto');
      }
    })
    .catch((err) => {
      console.error(err);
      if (err?.response?.data?.message) {
        messageApi.error(err.response.data.message);
      } else {
        messageApi.error('Ocurrió un error al crear el contacto');
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
