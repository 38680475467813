import { FaRegFilePowerpoint } from "react-icons/fa6";
import {
  FiPaperclip,
  FiShoppingCart,
  FiSliders,
  FiTag,
  FiUsers,
} from "react-icons/fi";
import { MdOutlineWorkspaces } from "react-icons/md";
import { AiOutlineApi, AiOutlinePlusSquare } from "react-icons/ai";
import { TbMessage } from "react-icons/tb";
import { accessDataFormat } from "../../../../../helpers/access";
import { LiaRobotSolid } from "react-icons/lia";
import { PiAddressBookBold, PiTicket } from "react-icons/pi";

const getItem = (label, key, icon, children, type) => {
  return {
    label,
    key,
    icon,
    children,
    type,
  };
};

export const settingsMenuItems = (isAllowed, isDevsInstance) => {
  return [
    // getItem("Workspace", "sub1", null, [
    isAllowed(accessDataFormat("workspaces", "manage")) &&
      getItem("Workspace", "workspace", <AiOutlineApi />),
    isAllowed(accessDataFormat("channels", "manage")) &&
      getItem("Canales", "channels", <FiSliders />),
    isAllowed(accessDataFormat("users", "manage")) &&
      getItem("Usuarios y roles", "users", <FiUsers />),
    isAllowed(accessDataFormat("teams", "manage")) &&
      getItem("Equipos", "teams", <MdOutlineWorkspaces />),
    isAllowed(accessDataFormat("contacts", "manage")) &&
      getItem("Contactos", "contacts", <PiAddressBookBold />),
    isAllowed(accessDataFormat("clients", "manage")) &&
      getItem("Clientes", "clients", <FiUsers />),
    isAllowed(accessDataFormat("bots", "manage")) &&
      getItem("Bots", "bots", <LiaRobotSolid />),
    isDevsInstance &&
      isAllowed(accessDataFormat("chatSettings", "manage")) &&
      getItem("Chat", "chat", <TbMessage />),
    isAllowed(accessDataFormat("storage", "manage")) &&
      getItem("Almacenamiento", "storage", <FiPaperclip />),
    isAllowed(accessDataFormat("tags", "manage")) &&
      getItem("Etiquetas", "tags", <FiTag />),
    isAllowed(accessDataFormat("templates", "manage")) &&
      getItem("Plantillas", "templates", <FaRegFilePowerpoint />),

    isDevsInstance &&
      isAllowed(accessDataFormat("catalog", "manage")) &&
      getItem("Productos y servicios", "catalog", <FiShoppingCart />),
    // isAllowed(accessDataFormat("savedMessages", "show")) &&
    //   getItem(
    //     "Mensajes predeterminados",
    //     "saved-messages",
    //     <LuSquareSlash />
    //   ),
    // isAllowed(accessDataFormat("errorMessages", "show")) &&
    //   getItem("Mensajes de error", "error-messages", <BiMessageError />),
    isAllowed(accessDataFormat("integrations", "manage")) &&
      getItem("Integraciones", "integraciones", <AiOutlinePlusSquare />),
    isAllowed(accessDataFormat("tickets", "manage")) &&
      getItem("Tickets", "tickets", <PiTicket />),
    //   ]
    // ),
    // getItem("Brocoly", "sub2", null, [

    // ]),
  ];
};

export const renderScope = (scope) => {
  let string = "";
  scope.forEach((scope) => {
    return (string += " " + scope);
  });

  string = string.trim();

  return string.replace(" ", " | ");
};

export const BUTTON_OPTIONS = [
  {
    title: "Botones de respuesta rápida",
    children: [
      {
        type: "MARKETING",
        title: "Desactivar marketing",
        subtitle: "Recomendado",
      },
      { type: "QUICK_REPLY", title: "Personalizado" },
    ],
  },
  {
    title: "Botones de llamada a la acción",
    children: [
      {
        type: "URL",
        title: "Ir al sitio web",
        subtitle: "2 botones como máximo",
      },
      {
        type: "PHONE_NUMBER",
        title: "Llamar al número de teléfono",
        subtitle: "1 botón como máximo",
      },
      {
        type: "COPY_CODE",
        title: "Copiar código de oferta",
        subtitle: "1 botón como máximo",
      },
    ],
  },
];
