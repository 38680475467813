import { createContext, useContext, useEffect, useState } from 'react';
import { GlobalContext } from './GlobalContext';
import {
  getCampaigns,
  getManyCampaignsMetrics
} from '../../helpers/fetchData/fetchCampaigns';

export const CampaignsContext = createContext([]);

export const CampaignsProvider = ({ children }) => {
  const { instance, messageApi } = useContext(GlobalContext);

  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  const [campaignsMetrics, setCampaignsMetrics] = useState(null);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null); // Debounce

  // Obtener datos de todas las campañas desde la API:
  const fetchDataCampaigns = async () => {
    if (!instance?.userData?.apiKey) {
      return;
    }

    setLoadingCampaigns(true);
    try {
      const res = await getCampaigns(instance.userData.apiKey);

      if (res?.status === 200) {
        setCampaigns(res.data);
      } else {
        messageApi.error('Ocurrió un error al obtener campañas');
      }
    } catch (err) {
      console.error(err);
      messageApi.error('Ocurrió un error al obtener campañas');
    } finally {
      setLoadingCampaigns(false);
    }
  };

  // Obtener datos de métricas de todas las campañas desde la API:
  const fetchDataCampaignsMetrics = async () => {
    if (!instance?.userData?.apiKey) {
      return;
    }

    setLoadingMetrics(true);
    try {
      const campaignIds = filteredCampaigns.map((campaign) => campaign._id);

      const res = await getManyCampaignsMetrics(instance, campaignIds);

      if (res?.status === 200) {
        setCampaignsMetrics(res.data);
      } else {
        messageApi.error(
          'Ocurrió un error al obtener las métricas de las campañas'
        );
      }
    } catch (err) {
      console.error(err);
      messageApi.error(
        'Ocurrió un error al obtener las métricas de las campañas'
      );
    } finally {
      setLoadingMetrics(false);
    }
  };

  // Cargar datos de campañas al renderizar por primera vez el componente Campaigns:
  useEffect(() => {
    if (!instance?.userData?.apiKey) {
      return;
    }

    if (!campaigns.length) {
      fetchDataCampaigns();
    }
  }, [instance]);

  // Campañas filtradas (necesario para el buscador):
  useEffect(() => {
    setFilteredCampaigns([...campaigns]);
  }, [campaigns]);

  // Cargar métricas de campañas al renderizar por primera vez el componente Campaigns o al buscar campañas:
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout); // Limpiar cualquier timeout anterior
    }

    // Nuevo timeout:
    const timeoutId = setTimeout(() => {
      if (filteredCampaigns.length) {
        fetchDataCampaignsMetrics();
      }
    }, 500);

    setSearchTimeout(timeoutId);

    // Limpiar el timeout cuando el componente se desmonte:
    return () => clearTimeout(timeoutId);
  }, [instance, filteredCampaigns]);

  return (
    <CampaignsContext.Provider
      value={{
        campaigns,
        setCampaigns,
        loadingCampaigns,
        filteredCampaigns,
        setFilteredCampaigns,
        fetchDataCampaigns,
        campaignsMetrics,
        loadingMetrics
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};
